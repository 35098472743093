<template>
  <div id="error-modal-container">
    <div id="error-content">
        <div id="error-msg">
          <h1>There was an error accessing that Playspaces</h1>
          <br/><br/>
          <div class="button-group" v-if="userData == null">
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>                 
          </div>     
          <div class="button-group" v-else>
            <b-button pill variant="primary" @click="goHome()">
              <div class="home-icon ps-icons"></div>
              {{$t('global.gohome')}}
            </b-button>  
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>                        
          </div>               
      </div>                       
      <div id="alien">
        <img src="/img/elements/error/404_alien.svg" width="573" height="555"/>
      </div>
      <div id="friends">
        <img src="/img/elements/error/404_friends.svg" width="181" height="617"/>
      </div>
    </div> 
  </div>  
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "PSError", 
  computed: {
    ...mapState(['userData']), 
  },  
  methods: {
    goHome() {
      this.tracking({
          type: 'event',
          category: 'playspace-error',
          action: 'error:gohome',
      });    
      this.$router.push('/home');       
    },
    moreInfo() {
      this.tracking({
          type: 'event',
          category: 'playspace-error',
          action: 'error:moreinfo',
      });  
      window.open('https://www.playspaces.co/faq','_blank');     
    },    
  },
  mounted () {
    // TODO add different playspace error messages

    this.tracking({
      type: 'page',
      title: '/playspace/error',
    });    
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#error-modal-container {
  position: absolute;
  top: 0px;
  margin: 0 auto;
  width: 1024px;
  height: 768px;
  #error-content{
    position:relative;
    z-index: 2000;
  }
  #error-msg {
    margin: 100px auto 30px;
    width: 400px;
    text-align: center;
      h1 {
        color: $ps-white;
      }  
    .button-group {
      .btn-primary {
        margin:0 15px 30px 0;
      }
    }        
  }
  #alien {
    position:absolute;
    z-index: -1;
    top: 60px;
    left: 15%;
  }
  #friends {
    z-index: -1;
    position:absolute;
    top: -175px;
    right: 80px;    
  }
}
</style>