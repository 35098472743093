<template>
    <div> 
    </div>
</template>

<script>

export default {
  name: "Playspace", 
  mounted () {
    
  }
};
</script>

<style scoped>

</style>